<template>
    <span>
        <v-btn
            color="success"
            @click="OpenDialog()"
            v-if="$store.state.admin.Admin == 1"
            >text</v-btn
        >
        <slot v-if="$store.state.admin.Admin == 1 && editor" />
        <div class="editor mt-5" v-if="editor && $store.state.admin.Admin == 1">
            <menu-bar class="editor__header" :editor="editor" />
            <editor-content class="editor__content" :editor="editor" />
            <v-btn color="success" @click="Submit">Submit</v-btn>
        </div>
        <!-- <v-dialog v-model="dialog" width="500">
                    
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                            Privacy Policy
                        </v-card-title>
        
                        <v-card-text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                            occaecat cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                        </v-card-text>
        
                        <v-divider></v-divider>
        
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialog = false">
                                I accept
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog> -->
    </span>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
//import Collaboration from '@tiptap/extension-collaboration'
//import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
//import CharacterCount from '@tiptap/extension-character-count'

import MenuBar from "./MenuBar.vue";

export default {
    props: {
        index: {
            type: String,
            default: "-1",
        },
        state: {
            type: String,
            default: "",
        },
        store: {
            type: String,
            default: "",
        },
        variable: {
            type: String,
            default: "",
        },
    },
    components: {
        EditorContent,
        MenuBar,
    },

    data() {
        return {
            dialog: false,

            provider: null,

            editor: null,

            articulo: {
                Titulo: "",
                Autor: "",
                HTML: "",
                Foto: require("../../assets/images/galery.png"),
            },
        };
    },

    mounted() {
        // localStorage.setItem('currentUser', JSON.stringify(this.currentUser))
    },

    methods: {
        OpenDialog() {
            if (this.index != -1) {
                console.log("IF");
                this.editor = new Editor({
                    content: JSON.parse(
                        this.$store.state[this.state][this.index][this.variable]
                    ),

                    extensions: [
                        StarterKit.configure({
                            history: true,
                        }),
                        Highlight,
                        TaskList,
                        TaskItem,
                        Text,
                        TextAlign.configure({
                            types: ["heading", "paragraph"],
                        }),
                    ],
                });
            } else {
             
                try {
                    console.log(this.$store.state[this.state][this.variable]);
                    this.editor = new Editor({
                        content: JSON.parse(
                            this.$store.state[this.state][this.variable]
                        ),

                        extensions: [
                            StarterKit.configure({
                                history: true,
                            }),
                            Highlight,
                            TaskList,
                            TaskItem,
                            Text,
                            TextAlign.configure({
                                types: ["heading", "paragraph"],
                            }),
                        ],
                    });
                } catch (e) {
                    this.editor = new Editor({
                        extensions: [
                            StarterKit.configure({
                                history: true,
                            }),
                            Highlight,
                            TaskList,
                            TaskItem,
                            Text,
                            TextAlign.configure({
                                types: ["heading", "paragraph"],
                            }),
                        ],
                    });
                }
            }
        },

        Submit() {
            
            // this.state = JSON.stringify(this.editor.getJSON());
            if (this.index != "-1") {
                this.$store.state[this.state][this.index][
                    this.variable
                ] = JSON.stringify(this.editor.getJSON());

                this.$store
                    .dispatch(
                        this.store,
                        this.$store.state[this.state][this.index]
                    )
                    .then((res) => {
                          console.log(res)
                        this.editor.destroy();
                        // this.provider.destroy();
                        this.editor = null;

                        // window.location.reload();
                        // this.dialog = false;
                    });
            } else {
                this.$store.state[this.state][this.variable] = JSON.stringify(
                    this.editor.getJSON()
                );
                console.log(this.$store.state[this.state]);
                this.$store
                    .dispatch(
                        this.store,
                        this.$store.state[this.state]
                    )
                    .then((res) => {
                        console.log(res)
                        this.editor.destroy();
                        // this.provider.destroy();
                        this.editor = null;
                        // window.location.reload();
                        // this.dialog = false;
                    });
            }

            // this.editor.destroy();

            // this.editor = null
        },
    },

    beforeDestroy() {
        try {
            this.editor.destroy();
        } catch (e) {}

        // this.provider.destroy();
    },
};
</script>

<style lang="scss" scoped>
.editor {
    display: flex;
    flex-direction: column;
    height: 400px;
    color: #0d0d0d;
    background-color: #fff;
    border: 3px solid #0d0d0d;
    border-radius: 0.75rem;

    &__header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        flex-wrap: wrap;
        padding: 0.25rem;
        border-bottom: 3px solid #0d0d0d;
    }

    &__content {
        padding: 1.25rem 1rem;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__footer {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        white-space: nowrap;
        border-top: 3px solid #0d0d0d;
        font-size: 12px;
        font-weight: 600;
        color: #0d0d0d;
        white-space: nowrap;
        padding: 0.25rem 0.75rem;
    }

    /* Some information about the status */
    &__status {
        display: flex;
        align-items: center;
        border-radius: 5px;

        &::before {
            content: " ";
            flex: 0 0 auto;
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            background: rgba(#0d0d0d, 0.5);
            border-radius: 50%;
            margin-right: 0.5rem;
        }

        &--connecting::before {
            background: #616161;
        }

        &--connected::before {
            background: #b9f18d;
        }
    }

    &__name {
        button {
            background: none;
            border: none;
            font: inherit;
            font-size: 12px;
            font-weight: 600;
            color: #0d0d0d;
            border-radius: 0.4rem;
            padding: 0.25rem 0.5rem;

            &:hover {
                color: #fff;
                background-color: #0d0d0d;
            }
        }
    }
}
</style>

<style lang="scss">
/* Give a remote user a caret */
.collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    word-break: normal;
    pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0d0d0d;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
}

/* Basic editor styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    mark {
        background-color: #faf594;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    hr {
        margin: 1rem 0;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }

    ul[data-type="taskList"] {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;

            > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
            }
        }
    }
}
</style>
