<template>
    <v-container>
        <v-row align="center" class="row--35">
            <v-col lg="5" md="5" cols="12">
                <div class="thumbnail">
                    <!-- <slot name="thum-img"></slot> -->
                    <img :src="$store.state.iglesia.Foto_Conocenos1" alt="" />
                </div>
            </v-col>
            <v-col lg="7" md="7" cols="12">
                <div class="about-inner inner">
                    <div class="section-title">
                        <div class="icon" v-html="iconSvg(icon)"></div>
                        <h2 class="heading-title">
                            {{ $store.state.iglesia.Titulo_Conocenos1 }}
                        </h2>
                        <!-- <p class="description">
                            {{ $store.state.iglesia.Info_Conocenos1 }}
                        </p> -->

                        <globaleditor
                            store="update_iglesia"
                            state="iglesia"
                            variable="Info_Conocenos1"
                        >
                            <div class="mt-5">
                                <v-text-field
                                    v-model="
                                        $store.state.iglesia.Titulo_Conocenos1
                                    "
                                    label="Titulo"
                                    outlined
                                ></v-text-field>
                            </div>
                        </globaleditor>
                        <formatter
                            :HTMLdata="$store.state.iglesia.Info_Conocenos1"
                        />

                        <!-- <p class="description">
              A small river named Duden flows by their place and supplies it with the
              necessary regelialia. It is a paradisematic country, in which roasted parts
              of sentences
            </p>
            <div class="purchase-btn">
              <router-link class="btn-transparent" to="/">PURCHASE IMROZ</router-link>
            </div> -->
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import globaleditor from "../globaleditor/index.vue";
import formatter from "../htmlformatter/index.vue";
import feather from "feather-icons";
import shareEditor from "../shareEditor/index.vue";
export default {
    components: {
        globaleditor,
        formatter,
        shareEditor,
    },
    data() {
        return {
            icon: "send",
        };
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
    },
};
</script>
