<template>
    <span>
        <editor-content :editor="editor" />
    </span>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
export default {
    props: {
        HTMLdata: {
            type: String,
        },
    },
    components: {
        Editor,
        EditorContent,
    },
    data() {
        return {
            editor: null,
        };
    },
    created() {
        this.formatter();
    },
    methods: {
        formatter() {
            let _this = this;
            try {
                this.editor = new Editor({
                    editable: false,
                    content: JSON.parse(_this.HTMLdata),
                    extensions: [
                        StarterKit.configure({
                            history: true,
                        }),
                        Highlight,
                        TaskList,
                        TaskItem,
                        Text,
                        TextAlign.configure({
                            types: ["heading", "paragraph"],
                        }),
                    ],
                });
            } catch (e) {}
        },
    },
};
</script>

<style></style>
